<!--views/modules/wechat/objecttype-add-or-update.vue-->
<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="类型名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="服务对象类型名称"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" v-if="dataForm.updateTime" prop="updateTime">
        <el-input v-model="dataForm.updateTime" disabled placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddOrUpdateMixin from "@/mixins/AddOrUpdate";

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        name: [
          {required: true, message: '服务对象名称不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.doInit(id, `/wechat/objecttype/info`, 'objectType').then((resp)=> {
        this.dataForm.updateTime = this.$utils.dateFormat('yyyy-MM-dd hh:mm', resp.updateTime)
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/objecttype/${!this.dataForm.id ? 'save' : 'update'}`,{
        'id': this.dataForm.id || undefined,
        'name': this.dataForm.name
      })
    }
  }
}
</script>
